import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  makeStyles,
  createStyles,
} from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: 24,
      borderRadius: '8px',
      backgroundColor: '#000',
      textAlign: 'center',
      color: '#fff',
    },
    title: {
      fontWeight: 700,
      fontSize: '30px !important',
      color: '#fff',
    },
    content: {
      marginBottom: 16,
      fontSize: '14px',
      color: '#fff',
    },
    timerText: {
      fontSize: '24px',
      fontWeight: 600,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      gap: '12px',
    },
    button: {
      padding: '6px 12px',
      fontWeight: 600,
      fontSize: '12px',
      minWidth: '80px',
    },
    confirmButton: {
      backgroundColor: '#8A2BE2',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#6a1cbf',
      },
    },
    logoutButton: {
      color: '#fff',
      border: '1px solid #fff',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
  })
)

interface TimeoutDialogProps {
  open: boolean
  onConfirm: () => void
  onLogout: () => void
  titleText: string
  contentText: string
  timerText?: string
}

const TimeoutDialog: React.FC<TimeoutDialogProps> = ({
  open,
  onConfirm,
  onLogout,
  titleText,
  contentText,
  timerText,
}) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      aria-labelledby='timeout-dialog-title'
      aria-describedby='timeout-dialog-description'
    >
      <DialogTitle id='timeout-dialog-title' className={classes.title}>
        <Typography variant='h5'>{titleText}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography id='timeout-dialog-description' className={classes.content}>
          {contentText}
          {timerText && <div className={classes.timerText}>{timerText}</div>}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onConfirm} className={`${classes.button} ${classes.confirmButton}`}>
          Confirm Session
        </Button>
        <Button onClick={onLogout} className={`${classes.button} ${classes.logoutButton}`}>
          Log Out
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TimeoutDialog
