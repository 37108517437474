import React, { useEffect, useRef, useState } from 'react'
import {
  fetchHistoryConversations,
  updateActiveConversation,
  updateActiveConversationId,
  updateConversations,
} from '../Chat/ChatSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { selectCurrentUser, updateCurrentUserStatus } from '../../Reducers/authSlice'
import { playSoundNotification } from '../../Utils/chat.utils'
import { updateSocket } from '../../Reducers/socketSlice'
import { updateProfilePictureOfUserAsync, fetchUsersInRoom } from '../../Reducers/userSlice'
import {
  fetchRoomChatHistoryConversations,
  updateActiveRoomConversation,
  updateActiveRoomConversationId,
  updateRoomConversations,
} from '../RoomChat/RoomChatSlice'
import { triggerRoomRefresh, updateRoom, updateRoomContent } from '../RoomContainer/RoomSlice'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  authService,
  contentService,
  Conversation,
  localStorageService,
  MessageActions,
  MessagePayload,
  MessageTypes,
  messagingService,
  MessagingTopics,
  userService,
  User,
} from '../../Services/services-index'
import { refreshJwtfunc } from '../../Services/Utils/axiosInterceptors'
import { ConfirmationDialog } from '../../Common/common-index'
import CallContainer from '../../Containers/CallContainer/CallContainer'
import { useToasts } from 'react-toast-notifications'
import { setSessionExpired } from '../../Containers/ProtectedPageContainer/protectedPageSlice'
import TimeoutDialog from '../../Common/Components/TimeoutDialog/TimeoutDialog'

interface ProtectedPageContainerProps {
  children: React.ReactNode
}
const ProtectedPageContainer: React.FC<ProtectedPageContainerProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const socket = useAppSelector((state) => state.socket.socket)
  const currentUser = useAppSelector(selectCurrentUser)
  const isSessionExpiredStatus = useAppSelector((state) => state.protectedPage.isSessionExpired)

  const allConversations = useAppSelector((state) => state.chat.conversations)
  const roomConversations = useAppSelector((state) => state.roomChat.conversations)
  const roomId = useAppSelector((state) => state.room.activeRoomId)
  const currentUserStatus = useAppSelector((state) => state.auth.currentUserStatus)
  const currentConversationId = useAppSelector((state) => state.chat.activeConversationId)
  const roomContent = useAppSelector((state) => state.room.roomContent)

  const [hasChatListener, setHasChatListener] = useState(false)
  const [socketMessageResponse, setSocketMessageResponse] = useState<MessagePayload>()
  const [messageToForward, setMessageToForward] = useState(null as MessagePayload | null)
  const [activeRoomId, setActiveRoomId] = useState<number>()
  const [currentRoom, setCurrentRoom] = useState<any>()
  const [currentRoomContent, setCurrentRoomContent] = useState<any>()
  const resetActivityTimersRef = useRef<(() => void) | null>(null)
  const [isTimeoutDialogOpen, setIsTimeoutDialogOpen] = useState(false)
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [timerText, setTimerText] = useState('')
  const lastActiveTimeRef = useRef<string | null>(null)
  // @ts-ignore
  const [lastActiveTime, setLastActiveTime] = useState<string | null>(null)
  let intervalId: NodeJS.Timeout | null = null
  let timeoutId: NodeJS.Timeout | null = null
  let timerId: NodeJS.Timeout | null = null
  const sessionConfirmedRef = useRef(false)
  const timerRef = useRef<NodeJS.Timeout | number | null>(null)
  let emitIntervalId: NodeJS.Timeout | null = null
  let isSocket = false
  let resetActivityTimersCalled = false
  // Fetch room and roomContent
  useEffect(() => {
    if (currentUser && !activeRoomId && !currentRoom && !currentRoomContent) {
      window?.location?.pathname?.includes('lobby')
        ? fetchRoom(currentUser)
        : fetchRoom(currentUser, parseInt(window.location.pathname.split('/')[2], 10))
    }
  }, [activeRoomId, currentRoom, currentRoomContent])
  useEffect(() => {
    if (currentUser && !activeRoomId && !currentRoom && !currentRoomContent) {
      window?.location?.pathname?.includes('lobby')
        ? handleUserActivity(currentUser)
        : handleUserActivity(currentUser, parseInt(window.location.pathname.split('/')[2], 10))
    }
  }, [currentUser])
  // Update room and roomContent in redux store
  useEffect(() => {
    if (currentRoom) {
      dispatch(updateRoom(currentRoom))
    }
    if (currentRoomContent) {
      dispatch(updateRoomContent(currentRoomContent))
    }
  }, [currentRoom, currentRoomContent])

  // Get room and user's conversations history
  useEffect(() => {
    if (currentUser && activeRoomId) {
      dispatch(
        fetchRoomChatHistoryConversations({
          userId: currentUser?.id as number,
          room_id: activeRoomId,
        })
      )
      dispatch(fetchHistoryConversations(currentUser?.id as number))
    }
  }, [activeRoomId])

  useEffect(() => {
    if (currentUser && roomId) {
      dispatch(fetchUsersInRoom(roomId))
    }
  }, [currentUser, roomId])

  useEffect(() => {}, [currentUserStatus])

  useEffect(() => {
    if (currentUser && socket && (allConversations || roomConversations)) {
      /**
       * Prevent duplicate listener
       */
      if (hasChatListener) {
        return
      }
      const { event_code } = currentUser
      const chatTopic = `${MessagingTopics.CHAT}_${event_code}`
      setHasChatListener(true)
      socket.on(chatTopic, (data: string) => {
        const parsedResponse = JSON.parse(data)
        setSocketMessageResponse(parsedResponse)

        /**
         * Audio or video call handling
         */
        switch (parsedResponse?.type) {
          case MessageTypes.text: {
            break
          }
          case MessageTypes.video:
          case MessageTypes.audio: {
            handleVideoAudioMessageAction(parsedResponse)
            return
          }
          default:
            return
        }
      })
      socket.on('user_recruiting_status', (message) => {
        const parsedMessage = JSON.parse(message)
        const roomContentCopy = JSON.parse(JSON.stringify(roomContent))
        parsedMessage.content.forEach((content: any) => {
          roomContentCopy.content.map((contentCpy: any) => {
            if (content.id === contentCpy.id) {
              Object.keys(content.attributes).forEach((contentAttribute) => {
                contentCpy.attributes[contentAttribute] = content.attributes[contentAttribute]
              })
            }
          })
        })
        dispatch(updateRoomContent(roomContentCopy))
      })
    }
  }, [socket, currentUser, allConversations, roomConversations, socketMessageResponse])

  useEffect(() => {
    if (socketMessageResponse && currentUser) {
      /**
       * Check conversation type
       */
      const isRoomConversation = socketMessageResponse?.conversation.indexOf('room') > -1
      const isGroupConversation = socketMessageResponse?.to?.length > 1
      const isCurrentUserMessage = socketMessageResponse.from === currentUser.id
      const isTemporary = socketMessageResponse?.conversation.indexOf('temp-') > -1
      const userId = socketMessageResponse.from

      // ending call socket message
      const isEndingCall =
        socketMessageResponse?.action === 'end' && socketMessageResponse?.type === 'video'
      /**
       * Handle bot id
       */
      const bot = socketMessageResponse.bot
      /**
       * Handle Room Conversation messages
       */
      if (
        isRoomConversation &&
        socketMessageResponse &&
        isTemporary !== undefined &&
        isCurrentUserMessage !== undefined &&
        userId !== undefined &&
        !isEndingCall
      ) {
        handleRoomMessages(socketMessageResponse, isTemporary, isCurrentUserMessage, userId)
      }

      /**
       * Handle One to One or Group Conversation messages
       */
      if (
        !isRoomConversation &&
        socketMessageResponse &&
        isTemporary !== undefined &&
        isCurrentUserMessage !== undefined &&
        isGroupConversation !== undefined &&
        !isEndingCall
      ) {
        /**
         * Add sound notification to the incoming messages
         */
        if (!isCurrentUserMessage) {
          playSoundNotification()
        }

        handleDirectMessages(
          socketMessageResponse,
          isTemporary,
          isCurrentUserMessage,
          isGroupConversation,
          bot
        )
      }
    }
    if (socketMessageResponse?.action === 'delete') {
      if (roomId) {
        dispatch(
          fetchRoomChatHistoryConversations({
            userId: currentUser?.id as number,
            room_id: roomId,
          })
        )
      }
    }
  }, [socketMessageResponse])
  /**
   * To fetch the actual room Id & room content
   */
  const fetchRoom = async (currentUser: User, roomId?: number) => {
    if (currentRoom?.link?.length || currentRoomContent?.id) {
      return
    } else {
      const roomPromise = roomId ? contentService.getRoom(roomId) : contentService.getLobby()
      roomPromise
        .then(async (res) => {
          const room = res?.data?.room
          if (!socket) {
            const socket = messagingService.onConnectMessagingSocket(currentUser, room.id)
            socket.on('connect', () => {
              dispatch(updateSocket(socket))
            })
            socket.on('connect_error', (error: any) => {
              console.error(error)
              authService.signOut().then(() => dispatch(updateCurrentUserStatus('logged out')))
            })
            socket.on('logout', (data: string) => {
              const parsedData = JSON.parse(data)
              if (parsedData['user_id'] === currentUser.id) {
                authService.signOut().then(() => dispatch(updateCurrentUserStatus('logged out')))
              }
            })
            socket.on('refresh', (message) => {
              const parsedMessage = JSON.parse(message)
              const refreshDelay = parsedMessage.refresh_delay || 1
              const redirectUrl = parsedMessage.url
              setTimeout(() => {
                redirectUrl ? (window.location.href = redirectUrl) : dispatch(triggerRoomRefresh())
              }, refreshDelay)
            })
            socket.on('profile_picture_change', (data) => {
              const parsedData = JSON.parse(data)
              if (currentUser.id !== parseInt(parsedData.user_id)) {
                dispatch(
                  updateProfilePictureOfUserAsync({
                    userId: parseInt(parsedData.user_id),
                    profilePicturePath: parsedData.profile_picture_path,
                  })
                )
              }
            })
            socket.on('refresh_token', async (data, callback) => {
              if (data.initiateRefreshFlag) {
                const refreshJwtResponseData = await refreshJwtfunc()
                currentUser.jwt_token = refreshJwtResponseData.user_data.jwt_token
              }
              callback('refreshToken message consumed')
            })
          }
          const roomContent = contentService.getAllContent(room?.id)
          roomContent.then(async () => {
            if (room && roomContent) {
              setActiveRoomId(room?.id)
              setCurrentRoomContent((await roomContent)?.data)
              setCurrentRoom(room)
            }
          })
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            addToast(t('restrictedRoomMessage.description'), {
              onDismiss: () => {},
              title: t('restrictedRoomMessage.title'),
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 5000,
            })
            setTimeout(() => {
              window.location.href = '/lobby'
            }, 5000)
          }
        })
    }
  }
  const handleUserActivity = async (currentUser: User, roomId?: number) => {
    try {
      const roomPromise = roomId ? contentService.getRoom(roomId) : contentService.getLobby()
      const res = await roomPromise
      const room = res?.data?.room
      isSocket = true
      if (!room) {
        console.error('Room data is missing')
        return
      }

      if (!socket) {
        const newSocket = messagingService.onConnectMessagingSocket(currentUser, room.id, isSocket)
        dispatch(updateSocket(newSocket))
        const emitTimeoutMessage = () => {
          if (isTimeoutDialogOpen) {
            return // Stop emitting timeout message if dialog is open
          }
          const currentLastActiveTime = lastActiveTimeRef.current
          newSocket.emit(
            'user_update_timeout',
            JSON.stringify({ last_active_time: currentLastActiveTime })
          )
        }
        const resetActivityTimers = () => {
          const newTime = new Date().toISOString()
          setLastActiveTime(newTime)
          lastActiveTimeRef.current = newTime
          emitTimeoutMessage()

          if (intervalId) {
            clearInterval(intervalId)
            intervalId = null
          }
          if (timeoutId) {
            clearTimeout(timeoutId)
            timeoutId = null
          }
          if (emitIntervalId) {
            clearInterval(emitIntervalId)
            emitIntervalId = null
          }
          const startTime = Date.now()

          intervalId = setInterval(() => {
            // Increment current time by 1 minute
            const elapsedMinutes = Math.floor((Date.now() - startTime) / 60000)
            if (elapsedMinutes === 11) {
              if (!emitIntervalId) {
                emitIntervalId = setInterval(emitTimeoutMessage, 1000)
              }
            } else if (elapsedMinutes === 12) {
              handleInactivityTimeout()
              if (intervalId) {
                clearInterval(intervalId)
                intervalId = null
              }
              intervalId = null
              if (emitIntervalId) {
                clearInterval(emitIntervalId)
                emitIntervalId = null
              }
            } else if (elapsedMinutes < 11) {
              emitTimeoutMessage()
            }
          }, 60000) // Run every 60,000 ms (1 minute)
        }
        resetActivityTimersRef.current = resetActivityTimers
        const handleInactivityTimeout = () => {
          if (intervalId) {
            clearInterval(intervalId)
            intervalId = null
          }
          let countdown = 180 // 3 minutes in seconds
          timerRef.current = setInterval(() => {
            countdown -= 1
            const minutes = Math.floor(countdown / 60)
            const seconds = countdown % 60
            const timerText = `${minutes}:${seconds.toString().padStart(2, '0')}`
            setTimerText(timerText)
            setIsTimeoutDialogOpen(true)
            if (countdown === 0) {
              if (timerRef.current) {
                clearInterval(timerRef.current as number)
                timerRef.current = null
              }
              if (!sessionConfirmedRef.current) {
                authService.signOut().then(() => {
                  localStorageService.clearLocalStorageItemValue('currentUser')
                  window.location.reload()
                  dispatch(updateCurrentUserStatus(undefined))
                })
              }
            }
          }, 1000) // Update every second
        }
        newSocket.on('user_update_timeout_response', (data) => {
          const newActiveTime = data.new_active_time
          if (newActiveTime) {
            setLastActiveTime(newActiveTime)
            lastActiveTimeRef.current = newActiveTime
            resetActivityTimers() // Refresh user timeout functionality
            if (isTimeoutDialogOpen) {
              setIsTimeoutDialogOpen(false) // Close timer dialog if it's shown
            }
          }
        })
        newSocket.on('connect', () => {
          document.removeEventListener('click', resetActivityTimers)
          document.addEventListener('click', resetActivityTimers)
          if (newSocket.connected && !resetActivityTimersCalled) {
            resetActivityTimers()
            resetActivityTimersCalled = true
          }
        })
      } else {
        const currentLastActiveTime = lastActiveTimeRef.current
        socket.emit(
          'user_update_timeout',
          JSON.stringify({ last_active_time: currentLastActiveTime })
        )
      }
    } catch (error) {
      console.error('Error fetching room data:', error)
    }
  }
  /**
   * To update direct conversations
   */
  const handleDirectMessages = (
    socketMessageResponse: MessagePayload,
    isTemporary: boolean,
    isCurrentUserMessage: boolean,
    isGroupConversation: boolean,
    bot: any
  ) => {
    let activeConversationId = ''
    if (allConversations && socketMessageResponse) {
      if (socketMessageResponse?.conversation?.includes('temp')) {
        activeConversationId = socketMessageResponse.conversation.split('temp-')[1]
      } else {
        activeConversationId = socketMessageResponse.conversation
      }

      /**
       * Check if conversationId already exists
       */
      const hasPreviousConversation = Object.keys(allConversations).includes(activeConversationId)
      if (hasPreviousConversation) {
        /**
         * Start conversation with existing user messages
         */
        if (socketMessageResponse.action === MessageActions.start) {
          const activeConversation = allConversations[activeConversationId]
          if (activeConversation) {
            const conversation: Conversation = {
              ...activeConversation,
            }
            dispatch(updateActiveConversationId(activeConversationId))
            dispatch(updateActiveConversation(conversation))
            dispatch(updateConversations({ [activeConversationId]: conversation }))
            return
          }
        } else {
          /**
           * Add messages to existing conversation
           */
          const activeConversation = allConversations[activeConversationId]
          if (activeConversation) {
            const finalConversation: Conversation = {
              ...activeConversation,
              lastMessage: socketMessageResponse,
              isTemporary: false,
              isUnread: !isCurrentUserMessage,
              messages: [...activeConversation.messages!, socketMessageResponse as MessagePayload],
            }
            if (currentConversationId === activeConversationId) {
              dispatch(updateActiveConversationId(activeConversationId))
              dispatch(updateActiveConversation(finalConversation))
            }
            dispatch(
              updateConversations({
                [activeConversationId]: finalConversation,
              })
            )
          }
          return
        }
      }

      /**
       * Start a New conversation for One to One or Group users
       */
      if (!hasPreviousConversation) {
        const newConversation: Conversation = {
          participants: [],
          lastMessage: socketMessageResponse,
          isTemporary,
          isRoomConversation: false,
          isGroupConversation,
          isUnread: !isCurrentUserMessage,
          messages: [socketMessageResponse],
        }

        if (bot) {
          newConversation.bot = bot
        }

        const userSummaryInfo = socketMessageResponse.user_summary_info

        if (Array.isArray(userSummaryInfo) && userSummaryInfo.length > 1) {
          newConversation.participants = [...userSummaryInfo]
          if (!currentConversationId || currentConversationId === activeConversationId) {
            dispatch(updateActiveConversationId(activeConversationId))
            dispatch(updateActiveConversation(newConversation))
            dispatch(updateConversations({ [activeConversationId]: newConversation }))
          } else if (currentConversationId !== activeConversationId) {
            dispatch(updateConversations({ [activeConversationId]: newConversation }))
          }
          return
        } else {
          getUserAndDispatchNewConversation(
            socketMessageResponse.to[0],
            newConversation,
            activeConversationId,
            false
          )
        }
      }
    }
  }

  const getUserAndDispatchNewConversation = async (
    userId: number,
    newConversation: Conversation,
    conversationId: string,
    isRoomConversation: boolean
  ) => {
    const response = await userService.getUserSummary(userId)
    if (response) {
      const conversationToDispatch = {
        ...newConversation,
        participants: [...newConversation.participants, response.data],
      }
      if (isRoomConversation) {
        dispatch(updateActiveRoomConversationId(conversationId))
        dispatch(updateActiveRoomConversation(newConversation))
        dispatch(updateRoomConversations({ [conversationId]: conversationToDispatch }))
      } else {
        dispatch(updateActiveConversationId(conversationId))
        dispatch(updateActiveConversation(newConversation))
        dispatch(updateConversations({ [conversationId]: conversationToDispatch }))
      }
      return
    }
  }

  /**
   * To update Room conversations
   */
  const handleRoomMessages = (
    socketMessageResponse: any,
    isTemporary: boolean,
    isCurrentUserMessage: boolean,
    userId: number
  ) => {
    let conversationId = ''
    if (roomConversations && socketMessageResponse) {
      if (socketMessageResponse?.conversation?.includes('temp')) {
        conversationId = socketMessageResponse.conversation.split('temp-')[1]
      } else {
        conversationId = socketMessageResponse.conversation
      }

      const hasPreviousConversation = Object.keys(roomConversations).includes(conversationId)
      if (hasPreviousConversation) {
        /**
         * Start conversation with existing room conversations
         */
        if (socketMessageResponse.action === MessageActions.start) {
          const activeRoomConversations = roomConversations[conversationId]
          if (activeRoomConversations) {
            const newMessage: Conversation = {
              ...activeRoomConversations,
            }
            dispatch(updateActiveRoomConversationId(conversationId))
            dispatch(updateActiveRoomConversation(newMessage))
            dispatch(updateRoomConversations({ [conversationId]: newMessage }))
            return
          }
        } else {
          /**
           * Add new messages to existing room conversation
           */
          const roomConversation = roomConversations[conversationId]
          if (roomConversation) {
            const newMessage: Conversation = {
              ...roomConversation,
              lastMessage: socketMessageResponse,
              isTemporary: false,
              isUnread: !isCurrentUserMessage,
              messages: [...roomConversation.messages!, socketMessageResponse as MessagePayload],
            }
            const isNewToConversation = roomConversations[conversationId].participants.find(
              (participant) => participant.id === userId
            )
              ? false
              : true
            if (isNewToConversation) {
              getUserAndDispatchNewConversation(userId, newMessage, conversationId, true)
            } else {
              dispatch(updateActiveRoomConversationId(conversationId))
              dispatch(updateActiveRoomConversation(newMessage))
              dispatch(updateRoomConversations({ [conversationId]: newMessage }))
            }
            return
          }
        }
      }

      /**
       * Handle new room conversation messages
       */
      if (!hasPreviousConversation) {
        const newRoomConversation: Conversation = {
          participants: [],
          lastMessage: socketMessageResponse,
          isTemporary,
          isRoomConversation: true,
          isGroupConversation: false,
          roomId: socketMessageResponse.room?.id,
          roomName: socketMessageResponse.room?.name,
          messages: [socketMessageResponse],
        }

        const userSummaryInfo = socketMessageResponse.user_summary_info

        if (Array.isArray(userSummaryInfo) && userSummaryInfo.length > 2) {
          newRoomConversation.participants = [...userSummaryInfo]
        }
        dispatch(updateActiveRoomConversationId(conversationId))
        dispatch(updateActiveRoomConversation(newRoomConversation))
        dispatch(updateRoomConversations({ [conversationId]: newRoomConversation }))
        return
      }
    }
  }
  const handleConfirmSession = () => {
    setIsTimeoutDialogOpen(false) // Close the dialog
    if (timerRef.current) {
      clearInterval(timerRef.current as number)
      timerRef.current = null
    }
    if (resetActivityTimersRef.current) {
      resetActivityTimersRef.current() // Reset the session timers
    }
    if (timerId) {
      clearInterval(timerId)
      timerId = null
    }
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
    if (intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }
  }
  /**
   * Handle audio / video call actions
   */
  const handleVideoAudioMessageAction = (message: MessagePayload) => {
    setMessageToForward(message)
  }

  return (
    <div>
      <ConfirmationDialog
        open={isSessionExpiredStatus === true}
        onConfirm={() => {
          history.push('/auth/login')
          authService.signOut().then(() => {
            localStorageService.clearLocalStorageItemValue('currentUser')
            window.location.reload()
            dispatch(setSessionExpired(true))
          })
        }}
        titleText={t('authPages.sessionExpired.title')}
        confirmText={t('authPages.sessionExpired.confirm')}
        contentText={t('authPages.sessionExpired.content')}
      />
      <ConfirmationDialog
        open={currentUserStatus === 'logged out' && !isSessionExpiredStatus}
        onConfirm={() => {
          history.push('/auth/login')
          authService.signOut().then(() => {
            localStorageService.clearLocalStorageItemValue('currentUser')
            window.location.reload()
            dispatch(updateCurrentUserStatus(undefined))
          })
        }}
        titleText={t('authPages.loggedOutDialog.title')}
        confirmText={t('authPages.loggedOutDialog.confirm')}
        contentText={t('authPages.loggedOutDialog.content')}
      />
      {isTimeoutDialogOpen && (
        <TimeoutDialog
          open={isTimeoutDialogOpen}
          onConfirm={handleConfirmSession}
          onLogout={() => {
            setIsTimeoutDialogOpen(false)
            history.push('/auth/login')
            authService.signOut().then(() => {
              localStorageService.clearLocalStorageItemValue('currentUser')
              window.location.reload()
              dispatch(updateCurrentUserStatus(undefined))
            })
          }}
          titleText={'Auto Logout'}
          contentText={'Your session is about to expire'}
          timerText={timerText}
        >
          {timerText && <div>Time remaining: {timerText}</div>}
        </TimeoutDialog>
      )}
      <CallContainer messageReceived={messageToForward} />
      {children}
    </div>
  )
}

export default ProtectedPageContainer
