import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { localStorageService, User, UserRole } from '../Services/services-index'
import { RootState } from './store'

export interface AuthState {
  currentUser?: User
  currentUserRole: UserRole[]
  currentRoom?: number
  currentUserStatus?: string
}

const initialState: AuthState = {
  currentUser: undefined,
  currentUserRole: [UserRole.Visitor],
  currentRoom: undefined,
  currentUserStatus: undefined,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateCurrentUser: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload
      if (action.payload) {
        state.currentUserRole = getUserRole(action.payload)
      }
    },
    laterRegisterUser: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload
    },
    updateRoomAccess: (state, action: PayloadAction<number[]>) => {
      if (state.currentUser) {
        state.currentUser.room_access = action.payload
        localStorageService.setLocalStorageItemValue(
          'currentUser',
          JSON.stringify(state.currentUser)
        )
      }
    },
    updateCurrentRoom: (state, action: PayloadAction<number>) => {
      state.currentRoom = action.payload
    },
    updateCurrentUserStatus: (state, action: PayloadAction<string | undefined>) => {
      state.currentUserStatus = action.payload
      localStorageService.setLocalStorageItemValue('activeUserStatus', state.currentUserStatus)
    },
  },
})

export const {
  updateCurrentUser,
  laterRegisterUser,
  updateRoomAccess,
  updateCurrentRoom,
  updateCurrentUserStatus,
} = authSlice.actions

export const selectCurrentUser = (state: RootState) => state.auth.currentUser
export const selectCurrentUserRole = (state: RootState) => state.auth.currentUserRole

export default authSlice.reducer
function getUserRole(payload: any): UserRole[] {
  const userRoles: UserRole[] = []
  if (payload && payload.roles) {
    payload.roles.map((Role: { role: UserRole }) => {
      return userRoles.push(Role.role as UserRole)
    })
  } else {
    console.log('Payload or roles are undefined.')
  }
  return userRoles
}
