import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'

import MenuList from '@material-ui/core/MenuList'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  ConfirmationDialog,
  Icon,
  MenuDrawer,
  MenuHeader,
  MenuListItem,
} from '../../Common/common-index'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { startRoomConversationAsync } from '../../Reducers/socketSlice'
import {
  authService,
  Conversation,
  eventService,
  localStorageService,
  messagingService,
  Preference,
} from '../../Services/services-index'
import { createHtmlIdString } from '../../Utils/utils'
import { RoomChatPages, switchRoomChatPage } from '../RoomChat/RoomChatSlice'
import UserAvatar from '../UserAvatar/UserAvatar'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from './MenuSlice'

interface Props {
  protectedRoutes: any[]
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      minHeight: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
      },
    },
    menuItem: {
      minHeight: '40px',
      '& div[class*="MuiListItem-root"]': {
        height: '80px',
      },
    },
    profileSetting: {
      '& div[class*="MuiListItem-root"]': {
        padding: 0,
      },
    },
    noTextDecoration: {
      textDecoration: 'none',
    },
    menuIcon: {
      display: 'flex',
      height: '56px',
      width: '56px',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 20,
      cursor: 'pointer',
      '& svg': {
        height: '56px !important',
        width: '56px !important',
        display: 'block',
      },
      '& div:nth-of-type(2)': { display: 'none' },
      '&:hover div:nth-of-type(1)': { display: 'none' },
      '&:hover div:nth-of-type(2)': { display: 'block' },
      '&.forceTop': {
        [theme.breakpoints.up('sm')]: {
          top: 20,
        },
      },
      '&:not(.forceTop)': {
        [theme.breakpoints.down('sm')]: {
          bottom: 20,
        },
        [theme.breakpoints.up('sm')]: {
          top: 20,
        },
      },
    },
    unread: {
      background: '#54b8f9',
      border: '1px solid white',
      borderRadius: '50%',
      height: '12px',
      position: 'absolute',
      right: '55px',
      width: '12px',
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        bottom: '20px',
      },
      [theme.breakpoints.up('sm')]: {
        top: '65px',
      },
    },
    buttonTooltip: {
      flexBasis: '33.33%',
      '& div[class*="MuiButtonBase-root"]': {
        '& div[class*="MuiListItemText-root"]': {
          display: 'none',
        },
        '& div[class*="MuiListItemIcon-root"]': {
          flex: 1,
        },
      },
    },
    bottomNavigationContainer: {
      display: 'flex',
      width: '100%',
      background: theme.palette.background.paper,
      zIndex: 1,
    },
    justifyContentEvenly: {
      justifyContent: 'space-evenly',
    },
    startEl: {
      color: theme.palette.primary.main,
    },
  })
)

const MenuContainer: FC<Props> = ({ protectedRoutes }) => {
  const classes = styles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const isMenuOpen = useAppSelector((state) => state.menu.isOpen)
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const socket = useAppSelector((state) => state.socket.socket)
  const roomId = useAppSelector((state) => state.room.activeRoomId)
  const preferences = useAppSelector((state) => state.preferences.selectedPreferences)
  const allConversations = useAppSelector((state) => state.chat.conversations)
  const isTraverseRoom = useAppSelector((state) => state.room.isTraverseRoom)

  const [exitSurveyUrl, setExitSurveyUrl] = useState<string>('')
  const [exitSurveyPreference, setExitSurveyIsActive] = useState<any>()
  const [hasSurveyModeActive, setHasSurveyModeActive] = useState<boolean>(false)
  const [logoutDialogOpen, setLogoutDialogOpen] = useState<boolean>(false)
  const [hasUnreadMessage, setHasUnreadMessage] = useState<boolean>(false)
  const settingRoute = protectedRoutes.filter((route) => route.path === '/settings')[0]

  const { mutate, error, isError, isSuccess } = useMutation(() => authService.signOut())

  // Check if there are any chat unread message notifications
  useEffect(() => {
    if (allConversations) {
      const unreadMessageValidation = Object.values(allConversations).filter(
        (conversation: Conversation) => {
          return conversation.isUnread ? true : false
        }
      )
      !!unreadMessageValidation?.length ? setHasUnreadMessage(true) : setHasUnreadMessage(false)
    }
  }, [allConversations, hasUnreadMessage])

  useEffect(() => {
    if (isError) {
      console.error(error)
    }
  }, [isError])

  const toggleMenuOpenState = () => {
    dispatch(toggleMenu(!isMenuOpen))
    dispatch(updateIsSideMenuOpen(!isMenuOpen ? true : false))
  }

  function encodeEventCode(eventCode: string) {
    const query: URLSearchParams = new URLSearchParams(encodeURIComponent(eventCode))
    return query.toString().slice(0, -1)
  }

  useEffect(() => {
    if (isSuccess) {
      const anonymousUser = localStorageService.getLocalStorageItemValue('anonymousUser')
      // Remove values from localstore
      localStorageService.clearLocalStorageItemValue('currentUser')
      localStorageService.clearLocalStorageItemValue('selectedApp')
      localStorageService.clearLocalStorageItemValue('enableRoomChatFeed')
      localStorageService.clearLocalStorageItemValue('activeUserStatus')
      localStorageService.clearLocalStorageItemValue('disabledPopups')
      localStorageService.clearLocalStorageItemValue('transitionContent')
      localStorageService.clearLocalStorageItemValue('backgroundMusicStatus')
      localStorageService.clearLocalStorageItemValue('enableMiniMap')
      localStorageService.clearLocalStorageItemValue('thirdPersonView')
      localStorageService.clearLocalStorageItemValue('anonymousUser')

      if (currentUser) {
        if (anonymousUser == 'true') {
          history.push('/auth/login?event=' + encodeEventCode(''))
        } else {
          history.push('/auth/login?event=' + encodeEventCode(currentUser?.event_code))
        }
      }
      window.location.reload()
    }
  }, [isSuccess, preferences])

  useEffect(() => {
    if (preferences) {
      const exitSurvey = preferences['exit_survey']
      const surveyUrl = preferences['exit_survey_url']
      if (exitSurvey && exitSurvey?.value) {
        setExitSurveyIsActive(exitSurvey)
      }
      surveyUrl && surveyUrl?.value
        ? setExitSurveyUrl(surveyUrl?.value.toString())
        : setExitSurveyUrl('')
      exitSurveyPreference?.value === 'yes' && exitSurveyUrl?.length
        ? setHasSurveyModeActive(true)
        : setHasSurveyModeActive(false)
    }
  }, [preferences, hasSurveyModeActive, exitSurveyPreference, exitSurveyUrl])

  const toggleLogoutDialog = () => {
    setLogoutDialogOpen(!logoutDialogOpen)
  }

  // Render Main menu items
  const renderMenuItem = (route: any) => {
    if (
      route.path === '/chat' &&
      preferences &&
      preferences['private_chat'] &&
      preferences['private_chat'].value === 'no'
    ) {
      return
    }
    return (
      <>
        {route && (
          <MenuListItem
            id={createHtmlIdString('menu-container-list-item-', route.name ? route.name + '' : '')}
            key={route.name}
            text={route.name}
            startIcon={route.icon}
            endEl={route.endElIcon}
            count={route.count}
            hasUnreadMessage={route.name === 'Chat' && hasUnreadMessage ? true : false}
            onClick={() => onMenuItemClick(route)}
          />
        )}
      </>
    )
  }

  // Navigate Menu item on click
  const onMenuItemClick = (route: any) => {
    if (roomId && route?.path === '/room-chat') {
      dispatch(startRoomConversationAsync({ roomId }))
      dispatch(switchRoomChatPage(RoomChatPages.RoomChatMessage))
      dispatch(switchMenuPage(route.menuPage))
    } else {
      dispatch(switchMenuPage(route.menuPage))
    }
  }

  // Click on "Confirm" button
  const handleConfirmation = () => {
    if (exitSurveyPreference?.value === 'yes' && exitSurveyUrl) {
      window.open(exitSurveyUrl)
    }

    // Trigger logout
    mutate()
  }

  // Click on "No" button
  const handleSurveyRejection = () => {
    if (exitSurveyPreference?.value === 'yes' && exitSurveyUrl) {
      updateSurveyRejection()
    }
  }

  // Update "exit_survey" preference value as "no"
  const updateSurveyRejection = () => {
    if (currentUser?.event_code) {
      const body: Preference = {
        value: 'no',
        name: 'exit_survey',
        category: 'general',
        user_id: currentUser?.id,
        event_code: currentUser?.event_code.toString(),
      }
      const preferenceId = exitSurveyPreference?.id

      if (preferenceId && body) {
        eventService
          .postPreference(body)
          .then((res: any) => {
            if (res.status === 200) {
              mutate()
              toggleLogoutDialog()
              updateUserStatus()
            }
          })
          .catch(() => {
            console.error('An unexpected error occurred. Please try again later.')
          })
      }
    }
  }

  // Upate user status as "offline"
  const updateUserStatus = () => {
    if (currentUser && socket) {
      messagingService.emitMessagingSocket(
        'user_status_changes',
        {
          room_id: roomId,
          user_id: currentUser.id,
          user_status: 'offline',
        },
        socket
      )
    }
  }

  return (
    <>
      <div id='side-menu-toggle-button'>
        <div
          className={isTraverseRoom ? `${classes.menuIcon} forceTop` : classes.menuIcon}
          onClick={toggleMenuOpenState}
        >
          <div>
            <Icon
              iconName={isTraverseRoom ? 'burgerMenu' : 'mainMenu'}
              title={'Menu'}
              iconSize='56px'
            />
          </div>
          <div>
            <Icon iconName={'mainMenuHover'} title={'Menu'} iconSize='56px' />
          </div>
        </div>
        {hasUnreadMessage && <div className={classes.unread}></div>}
      </div>
      <MenuDrawer
        isOpen={isMenuOpen}
        onClose={toggleMenuOpenState}
        drawerFooter={
          <Box
            className={`
              ${classes.bottomNavigationContainer}
              ${isTraverseRoom ? classes.justifyContentEvenly : ''}`}
          >
            {settingRoute.hasAccess && (
              <>
                <div
                  id='v-b-tooltip.hover'
                  title={t('settings.title') || ''}
                  className={classes.buttonTooltip}
                >
                  <MenuListItem
                    id={createHtmlIdString('menu-container-list-item-settings')}
                    key={'settings'}
                    startIcon={<Icon iconName={'settings'} iconSize={'25px'} />}
                    onClick={() => dispatch(switchMenuPage(MenuPages.Settings))}
                    text={''}
                  />
                </div>
              </>
            )}
            <div
              id='v-b-tooltip.hover'
              title={t('helpMenu.title') || ''}
              className={classes.buttonTooltip}
            >
              <MenuListItem
                id={createHtmlIdString('menu-container-list-item-help')}
                key={'help'}
                startIcon={<Icon iconName={'help'} iconSize={'25px'} />}
                onClick={() => dispatch(switchMenuPage(MenuPages.Help))}
                text={''}
              />
            </div>
            <div
              id='v-b-tooltip.hover'
              title={t('authPages.logout.title') || ''}
              className={classes.buttonTooltip}
            >
              <MenuListItem
                id={createHtmlIdString('menu-container-list-item-log-out')}
                key={'log-out'}
                startIcon={<Icon iconName={'logOut'} iconSize={'25px'} />}
                onClick={toggleLogoutDialog}
                text={''}
              />
            </div>
          </Box>
        }
        drawerHeader={
          <MenuHeader
            title={t('menuDrawer.title')}
            isMainMenu={true}
            onClose={toggleMenuOpenState}
          />
        }
      >
        <div className={classes.container}>
          <MenuList>
            {protectedRoutes
              .filter(
                (r) => r.menuPage !== undefined && r.path !== '/settings' && r.path !== '/help'
              )
              .map((route) =>
                route.isActiveRoute ? (
                  <div className={classes.menuItem} key={route.path}>
                    <Link to={route.path} className={classes.noTextDecoration}>
                      {renderMenuItem(route)}
                    </Link>
                  </div>
                ) : (
                  <div className={route.hasAccess ? classes.menuItem : ''} key={route.path}>
                    {route.hasAccess && (
                      <>
                        {route.name === 'profile-settings' && (
                          <div className={classes.profileSetting}>
                            <MenuListItem
                              id='menu-container-profile-settings-avatar'
                              text={t('profileSettingsPage.title')}
                              startAvatar={
                                <UserAvatar
                                  userId={currentUser?.id}
                                  hasUnreadMessage={false}
                                  width={42}
                                  height={42}
                                />
                              }
                              onClick={() => dispatch(switchMenuPage(route.menuPage))}
                            />
                          </div>
                        )}
                        {route.name !== 'profile-settings' && renderMenuItem(route)}
                      </>
                    )}
                  </div>
                )
              )}
          </MenuList>
        </div>
      </MenuDrawer>
      {logoutDialogOpen && (
        <ConfirmationDialog
          titleText={t('authPages.logoutDialog.titleText')}
          contentText={
            hasSurveyModeActive
              ? t('authPages.logoutDialog.surveyText')
              : t('authPages.logoutDialog.logoutText')
          }
          cancelText={t('authPages.logoutDialog.cancelText')}
          confirmText={t('authPages.logoutDialog.confirmText')}
          open={logoutDialogOpen}
          showSurveyMode={hasSurveyModeActive}
          onConfirm={handleConfirmation}
          onCancel={toggleLogoutDialog}
          onRejection={handleSurveyRejection}
        />
      )}
    </>
  )
}

export default MenuContainer
