import React, { FC } from 'react'
import { useAppSelector } from '../../Reducers/hooks'
import AccountDeleteSuccessContainer from './AccountDeleteSuccessContainer'
import ChangePasswordContainer from './ChangePasswordContainer'
import ExportChatMessages from './ExportPrivateChatsContainer'
import ChangeStatusContainer from './ChangeStatusContainer'
import InterestsContainer from './InterestsContainer'
import ProfileInformationContainer from './ProfileInformationContainer'
import { ProfileSettingsContainer } from './ProfileSettingsContainer'
import { AppearanceSettingsContainer } from './AppearanceSettingsContainer'
import { ProfileSettingPages } from './profileSettingsSlice'
import SkillsContainer from './SkillsContainer'
export interface Props {
  roomIframeRef: React.RefObject<HTMLIFrameElement>
}
const ProfileSettings: FC = () => {
  const activeProfileSettingPage = useAppSelector(
    (state) => state.profileSettings.activeProfilePage
  )

  switch (activeProfileSettingPage) {
    case ProfileSettingPages.ProfileSettingsHome:
      return <ProfileSettingsContainer />
    case ProfileSettingPages.ChangeStatusPage:
      return <ChangeStatusContainer />
    case ProfileSettingPages.ProfileInformationPage:
      return <ProfileInformationContainer />
    case ProfileSettingPages.SkillsPage:
      return <SkillsContainer />
    case ProfileSettingPages.AppearanceSettingsPage:
      return <AppearanceSettingsContainer />
    case ProfileSettingPages.InterestsPage:
      return <InterestsContainer />
    case ProfileSettingPages.ChangePasswordpage:
      return <ChangePasswordContainer />
    case ProfileSettingPages.ExportPrivateChatsPage:
      return <ExportChatMessages />
    case ProfileSettingPages.AccountDeleteSuccessPage:
      return <AccountDeleteSuccessContainer />
    default: {
      return null
    }
  }
}

export default ProfileSettings
