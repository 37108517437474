/* eslint-disable no-unused-vars */
import axios from 'axios'
import { User } from '../Models/user.model'
import { FrontendTypes } from '../Models/app.model'
import Config from '../../config.json'

const reactAppDomain = Config.VSF_K8S_API_URL
const apiVersion = Config.VSF_K8S_API_VERSION
export const servicePrefix = `${reactAppDomain}/auth${apiVersion}`

enum PasswordTypes {
  CHANGE = 'change',
  RESET = 'reset',
}

export const signIn = (params: {
  email: string
  password: string
  event_code?: string
  frontend?: FrontendTypes
  force_login?: boolean
}) => {
  return axios.post(`${servicePrefix}/signin`, { ...params })
}

export const authCode = (params: { client_challenge: string }, headers: any) => {
  return axios.post(`${servicePrefix}/authCode`, params, {
    withCredentials: true,
    headers: headers,
  })
}

export const refreshJwt = () => {
  return axios.post(
    `${servicePrefix}/refreshJwt`,
    {},
    {
      withCredentials: true,
    }
  )
}

export const signup = (user: User, sendEmail: boolean) => {
  return axios.post(`${servicePrefix}/signup`, user, {
    params: {
      send_email: sendEmail,
    },
  })
}

const postPassword = (type: PasswordTypes, username: string, arg1?: string, arg2?: string) => {
  if (type === PasswordTypes.RESET) {
    return axios.post(
      `${servicePrefix}/password`,
      {
        username,
        event_code: arg1,
      },
      {
        params: {
          type,
        },
      }
    )
  } else {
    return axios.post(
      `${servicePrefix}/password`,
      {
        username,
        password: arg1,
        new_password: arg2,
      },
      {
        params: {
          type,
        },
      }
    )
  }
}

export const resetPassword = (username: string, eventCode?: string) => {
  return postPassword(PasswordTypes.RESET, username, eventCode)
}

export const changePassword = (username: string, oldPassword: string, newPassword: string) => {
  return postPassword(PasswordTypes.CHANGE, username, oldPassword, newPassword)
}
export const changePassword2 = (params: {
  username: string
  oldPassword: string
  newPassword: string
}) => {
  return postPassword(PasswordTypes.CHANGE, params.username, params.oldPassword, params.newPassword)
}

export const signOut = () => {
  return axios.get(`${servicePrefix}/signout`)
}

export const logoutUser = (query: number | string) => {
  // TODO: not in use in the application
  if (typeof query === 'number') {
    return axios.delete(`${servicePrefix}/session`, {
      params: {
        user_id: query,
      },
    })
  } else {
    return axios.delete(`${servicePrefix}/session`, {
      params: {
        event_code: query,
      },
    })
  }
}

export const getOauth2 = (frontend: string, provider: string) => {
  // TODO: not in use in the application
  return axios.get(`${servicePrefix}/oauth2`, {
    params: {
      frontend,
      provider,
    },
  })
}

export const postOauth2 = (
  frontend: string,
  provider: string,
  event_code: string,
  access_code: string,
  retry_token: string,
  callbackCode: string
) => {
  return axios.post(
    `${servicePrefix}/callback/oauth2`,
    {
      frontend,
      provider,
      event_code,
      ...(access_code ? { access_code } : {}),
      ...(retry_token ? { retry_token } : {}),
    },
    {
      params: {
        code: callbackCode,
      },
    }
  )
}

enum AccessTypes {
  ACTION = 'action',
  SCREEN = 'screen',
  ROLE = 'role',
}

export const changeAccessAction = () => {
  // TODO: not in use in the application
  return axios.put(
    `${servicePrefix}/access`,
    {
      action: 'POST',
      endpoint: '/sessions',
      topic: 'topic',
      default: false,
      event_id: 3,
      type: 'personal',
      role_id: 2,
    },
    {
      params: {
        type: AccessTypes.ACTION,
      },
    }
  )
}

export const changeAccessScreen = () => {
  // TODO: not in use in the application
  return axios.put(
    `${servicePrefix}/access`,
    {
      name: 'profile',
      frontend_name: '/profile',
      default: true,
      event_id: 3,
      type: 'personal',
      role_id: 2,
    },
    {
      params: {
        type: AccessTypes.SCREEN,
      },
    }
  )
}

export const changeAccessRole = (roleId: number, userId: number, roomId?: number) => {
  // TODO: not in use in the application
  return axios.put(
    `${servicePrefix}/access`,
    {
      role_id: roleId,
      room_id: roomId,
      user_id: userId,
    },
    {
      params: {
        type: AccessTypes.ROLE,
      },
    }
  )
}

export const getAccessAction = () => {
  // TODO: not in use in the application
  return axios.get(`${servicePrefix}/access`, {
    params: {
      type: AccessTypes.ACTION,
    },
  })
}

export const getAccessScreen = () => {
  // TODO: not in use in the application
  return axios.get(`${servicePrefix}/access`, {
    params: {
      type: AccessTypes.SCREEN,
    },
  })
}

export const getAccessRole = (userId: number) => {
  // TODO: not in use in the application
  return axios.get(`${servicePrefix}/access`, {
    params: {
      type: AccessTypes.ROLE,
      user_id: userId,
    },
  })
}

export const deleteAccessAction = (id: number) => {
  // TODO: not in use in the application
  return axios.delete(`${servicePrefix}/access`, {
    data: {
      id,
    },
    params: {
      type: AccessTypes.ACTION,
    },
  })
}

export const deleteAccessScreen = (id: number) => {
  // TODO: not in use in the application
  return axios.delete(`${servicePrefix}/access`, {
    data: {
      id,
    },
    params: {
      type: AccessTypes.SCREEN,
    },
  })
}

export const deleteAccessRole = (userId: number) => {
  // TODO: not in use in the application
  return axios.delete(`${servicePrefix}/access`, {
    data: {
      user_id: userId,
    },
    params: {
      type: AccessTypes.ROLE,
    },
  })
}

export const getProviders = (frontend: FrontendTypes, event_code: string | null) => {
  return axios.get(`${servicePrefix}/providers`, { params: { frontend, event_code } })
}

export const signinAnonymous = (params: { event_code: string; frontend: FrontendTypes }) => {
  return axios.post(`${servicePrefix}/signinAnonymous`, { ...params })
}
